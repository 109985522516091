import React from 'react';
import { Helmet } from 'react-helmet';
import { compose } from 'recompose';
import styled from 'styled-components';

import * as CONFIG from '../../SiteConfig';

import {
  withAuthorization,
  withEmailVerification,
} from '../components/particles/Session';

import Installer from '../components/organisms/installer/Installer';

import Layout from '../components/templates/Default';

const MapContainer = styled.section`
  display: flex;
  flex-grow: 1;
  max-height: 95vh;
  max-height: calc(100vh - 64px);
  width: 100%;

  > * {
    flex: 1;
  }
`;

const InstallPageBase = props => {
  return (
    <MapContainer className="map-container">
      <Installer />
    </MapContainer>
  );
};

const condition = authUser => !!authUser;

const InstallPage = compose(
  withEmailVerification,
  withAuthorization(condition),
)(InstallPageBase);

const InstallPageWrapper = props => {
  const activeEnv =
    process.env.GATSBY_ACTIVE_ENV ||
    process.env.NODE_ENV ||
    'development';

  return (
    <Layout page="homepage" align="center">
      <Helmet
        title={`${CONFIG.SITE_TITLE} | Installation`}
        defer={false}
      />
      {activeEnv === `production` ? (
        <InstallPage {...props} />
      ) : (
        <InstallPageBase {...props} />
      )}
    </Layout>
  );
};

export default InstallPageWrapper;
