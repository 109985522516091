import React, { useContext, useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Link } from 'gatsby';
import Papa from 'papaparse';

import StyledInstaller from './Installer-Styles';

import {
  DBServiceDeleteAll,
  DBServiceImport,
  DBServiceAll,
} from '../../particles/DBService/DBService';
import * as ROUTES from '../../particles/constants/routes';
import ApplicationContext from '../../particles/context/ApplicationContext';

import Loader from '../../molecules/loader/Loader';

const IMPORT_QUANTITY = 1000;

const Installer = () => {
  const [count, setCount] = useState(0);
  const [csvLength, setCSVLength] = useState(0);
  const [importing, setImporting] = useState(false);
  const [searching, setSearching] = useState(true);

  const updateCount = async () => {
    const dbAll = await DBServiceAll();
    if (dbAll) setCount(dbAll.length);
    setSearching(false);
  };

  useEffect(() => {
    updateCount();
  }, []);

  const createDB = async json => {
    setImporting(true);

    const CSV_FILE_LENGTH = json.length;
    setCSVLength(CSV_FILE_LENGTH);

    const loops = Math.ceil(CSV_FILE_LENGTH / IMPORT_QUANTITY);

    for (let i = 0; i < loops; i++) {
      const startOfArray = i * IMPORT_QUANTITY;
      const endOfArray = (i + 1) * IMPORT_QUANTITY;
      const data = json.slice(startOfArray, endOfArray);

      await DBServiceImport(data, i);
      await updateCount();
    }

    setImporting(false);
    return 'Complete';
  };

  const deleteDB = async e => {
    e.preventDefault();
    if (
      window.confirm(
        'Are you sure you want to delete your database? You will need to import a new CSV file if you do.',
      )
    )
      await DBServiceDeleteAll();
    updateCount();
  };

  const completeParsing = async (results, file) => {
    console.log('Parsing complete:', results, file);
    await createDB(results.data);
  };

  const errorParsing = (error, file) => {
    console.error('Error parsing:', error, file);
  };

  const parseFile = async file => {
    const res = Papa.parse(file, {
      complete: completeParsing,
      error: errorParsing,
      header: true,
    });
    return res;
  };

  const { state } = useContext(ApplicationContext);

  return (
    <>
      {importing && (
        <Loader percentage={count / csvLength}>
          <h2 className="h5">Please do not close this page.</h2>
          <p>
            Currently importing {count} of {csvLength} records.
          </p>
          <p>
            (Note: If continuing an import, initial checks take up to
            2 minutes to remove duplicates.)
          </p>
        </Loader>
      )}
      <StyledInstaller mode={state.theme}>
        <nav>
          <Link to={ROUTES.HOME}>View Map</Link>
          <div>
            <Link to={ROUTES.LICENCE}>Licence</Link>
         </div>
          <div>
            <Link to={ROUTES.INSTRUCTIONS}>Instructions</Link>
            {/*<Link to={ROUTES.CSV_FORMATTING}>
              View CSV Format Rules
            </Link>*/} 
          </div>
        </nav>
        <h2 className="h4">Install a database with a CSV file</h2>
        
        {searching ? (
          <p>Searching for a local database...</p>
        ) : (
          <>
            {count > 0 ? (
              <>
                <p>
                  Local databse found with {count} records.
                </p>
                <p>Upload a new file to upgrade this database.</p>
              </>
            ) : (
              <>
                <p>No local database found.</p>
                <p>
                  To install one, use the form below and upload a CSV
                  file.
                </p>
              </>
            )}
          </>
        )}
        <Formik
          initialValues={{ csvFile: null }}
          validate={values => {
            let errors = {};
            if (!values.csvFile)
              errors.csvFile =
                'Please upload a CSV file';
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            await parseFile(values.csvFile);
            setSubmitting(false);
          }}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {({
            errors,
            values,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => (
            <form
              onSubmit={handleSubmit}
              method="GET"
              aria-busy={searching || isSubmitting}
            >
              <input
                disabled={searching}
                accept=".csv"
                id="csvFile"
                name="csvFile"
                type="file"
                required
                onChange={event => {
                  setFieldValue(
                    'csvFile',
                    event.currentTarget.files[0],
                  );
                }}
                className="form-control"
              />
              {errors && errors.csvFile && (
                <label className="error" htmlFor="csvFile">
                  Sorry! {errors.csvFile}
                </label>
              )}
              <nav className="form__buttons">
                <button
                  type="submit"
                  value="Upload file"
                  disabled={searching || isSubmitting}
                >
                  Upload file
                </button>
                <a
                  href={process.env.GATSBY_EXTERNAL_CSV_FILE}
                  download
                  className="button button--secondary"
                  disabled={searching || isSubmitting}
                >
                  Download Latest CSV
                </a>
                <button
                  type="reset"
                  className="button button--secondary"
                >
                  Reset Form
                </button>
                <button
                  className="button button--tertiary"
                  disabled={searching || isSubmitting}
                  onClick={deleteDB}
                  type="button"
                >
                  Delete Database
                </button>
              </nav>
            </form>
          )}
        </Formik>
      </StyledInstaller>
    </>
  );
};

export default Installer;
