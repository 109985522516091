import styled from 'styled-components';
import { device } from '../../particles/MediaQueries';

import DotPattern from '../../../assets/images/patterns/dot-grid.png';
import PatternDark from '../../../assets/images/patterns/escheresque_ste.png';

const StyledInstaller = styled.nav`
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%:
  justify-content: center;
  max-width: 100%;
  padding: 64px 32px;
  width: 100%;

  background-color: ${props =>
    props.mode === 'dark' ? props.theme.black : props.theme.white};
  background-image: url(' ${props =>
    props.mode === 'dark' ? PatternDark : DotPattern} ');
  color: ${props =>
    props.mode === 'dark' ? props.theme.white : props.theme.black};

  > * {
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
    width: 100%;

    text-align: left;
  }

  p {
    font-size: 18px;
  }

  nav {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 auto 32px;

    a + a {
      margin-left: 16px;
    }

    .button {
      margin: 0;
      padding-right: 0;
    }
  }

  input {
    margin: 32px auto;
  }

  .form__buttons {
    margin: 32px auto 0;
    justify-content: unset;

    .button,
    button {
      margin: 0;
      padding: 16px 24px;

      + .button,
      + button {
        margin-left: 8px;
      }
    }
  }

  @media ${device.sm} {
  }
`;

export default StyledInstaller;
